import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const ROUTES: Routes = [
  {
    path: ':token',
    loadChildren: () => import('./pages/components/payment/payment.module').then(m => m.PaymentModule)
  }, {
    path: '',
    loadChildren: () => import('./pages/components/payment/payment.module').then(m => m.PaymentModule)
  }, {
    path: '**',
    loadChildren: () => import('./core/components/errors/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
